import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, LinkBox, Link, Box, Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"background": "--color-primary",
	"quarkly-title": "Footer-16"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "20%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "100%",
			"href": "#"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://bosphorusbeauty.com/img/5950392.png",
			"display": "block",
			"align-self": "flex-start",
			"width": "60px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"lg-width": "100%",
			"justify-content": "flex-end",
			"align-items": "center",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-grid-gap": "24px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 50px 0px 10px",
			"lg-align-items": "flex-start",
			"justify-content": "center",
			"display": "grid",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-justify-content": "flex-start",
			"sm-margin": "0px 0 30px 0",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "8px 15px",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "--light",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"text-align": "center",
			"justify-content": "center",
			"children": "Ana Sayfa"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"href": "/service",
			"text-decoration-line": "initial",
			"color": "--light",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"justify-content": "center",
			"children": "hizmetlerimiz"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"href": "/faq",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "--light",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"justify-content": "center",
			"children": "SSS"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"padding": "0 0 0 0",
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"href": "/contacts",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "--light",
			"display": "flex",
			"letter-spacing": "1px",
			"justify-content": "center",
			"children": "Kişiler"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"justify-content": "space-between",
			"padding": "30px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "#2c3339",
			"lg-flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-align-self": "stretch",
			"sm-flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"color": "--light",
			"md-margin": "0px 0px 0 0px",
			"md-width": "100%",
			"margin": "0 30px 0 0px",
			"children": "bosphorusbeauty.com © 2024 Her hakkı saklıdır."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Image {...override("image")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Text {...override("text")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;